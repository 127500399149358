<template>
  <!-- removing 'theming classes' will cause style issue throughout the site -->
  <main ref="mainWrap" class="tw-w-full" :class="{ 'tw-dark': !!DARK_MODE }">
    <modio-toast />
    <div
      :id="SCROLL_TO_ID"
      ref="container"
      class="tw-flex tw-flex-col tw-h-full tw-w-full tw-relative tw-util-scrollbar tw-overflow-y-auto tw-overflow-x-hidden tw-text-theme"
    >
      <nav-menu />
      <router-view :key="$route.path" v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
    </div>
    <global-modals />
    <menu-overlay />
  </main>
</template>

<script>
import { gameStore, authStore, headerStore, localModStore } from '@stores'
import { getPublicGameRequest, updateLocalStorage } from '@services'
import ViewContainer from '@components/View/ViewContainer.vue'
import MenuOverlay from '@components/Navbar/MenuOverlay.vue'
import ModioToast from '@components/Message/ModioToast.vue'
import GlobalModals from '@views/layouts/GlobalModals.vue'
import * as sdkInterface from '@plugins/sdkInterface.js'
import { onMounted, nextTick, watch, ref } from 'vue'
import { DARK_MODE, hostURL } from '@config/game.js'
import NavMenu from '@components/Navbar/NavMenu.vue'
import webInterface from '@plugins/webInterface.js'
import { SCROLL_TO_ID } from '@config'
import Widget from '@views/Widget.vue'
import {
  useStoreWatcher,
  useSuggestions,
  useBoundaries,
  useTheming,
  useAsync,
  useRoute,
  useSeo,
} from '@composables'
export default {
  components: {
    ViewContainer,
    GlobalModals,
    MenuOverlay,
    ModioToast,
    NavMenu,
    Widget,
  },
  setup() {
    const { routeName, getGameId } = useRoute()
    const { init } = useStoreWatcher()
    const { watchTheme } = useTheming()
    const { setBoundaryVars } = useBoundaries()
    const container = ref(null)
    const mainWrap = ref(null)
    const { addPublicGame } = gameStore()
    const { setToken } = authStore()
    const { setPortal, setPlatform } = headerStore()
    const { setLocalMods } = localModStore()
    const { suggestionRouteName, clearSuggestions } = useSuggestions()
    const gameNameId = getGameId().value

    const {
      data: gameData,
      run: runFetchGame,
      error: gameErrors,
    } = useAsync(() => getPublicGameRequest(gameNameId))

    // watch for route changes to
    // set correct theming options
    watchTheme()

    watch(routeName, (newRouteName) => {
      if (
        suggestionRouteName.value &&
        newRouteName !== suggestionRouteName.value
      ) {
        clearSuggestions()
      }
    })

    onMounted(async () => {
      if (location.pathname !== '/') {
        location.pathname = '/'
        return
      }

      useSeo().handleSeo()
      init()
      _setWrapVars()

      // Fetch language first so correct headers are sent
      await fetchLanguageFromSdk()

      fetchTokenFromSdk()
      fetchPortalFromSdk()
      fetchPlatformFromSdk()
      fetchLocalModsFromSdk()
      fetchGame()

      new ResizeObserver(() => {
        window.parent.postMessage(
          {
            height: container.value.scrollHeight,
            modioHeight: container.value.scrollHeight,
          },
          hostURL
        )
      }).observe(container.value)
      setTimeout(() => {
        window.parent.postMessage(
          {
            height: container.value.scrollHeight,
            modioHeight: container.value.scrollHeight,
          },
          hostURL
        )
      }, 1000)
    })

    async function fetchGame() {
      await runFetchGame()

      if (!gameErrors.value) {
        addPublicGame(gameData.value)
      }
    }

    async function fetchTokenFromSdk() {
      const token = await sdkInterface.getAuthToken()
      if (token) {
        setToken(token)
        updateLocalStorage()
      }
    }

    async function fetchLocalModsFromSdk() {
      const localMods = await sdkInterface.getLocalMods()
      if (localMods) {
        setLocalMods(localMods)
      }
    }

    async function fetchPortalFromSdk() {
      const portal = await sdkInterface.getPortal()
      if (portal) {
        setPortal(portal)
      }
    }

    async function fetchPlatformFromSdk() {
      const platform = await sdkInterface.getPlatform()
      if (platform) {
        setPlatform(platform)
      }
    }

    async function fetchLanguageFromSdk() {
      const lang = await sdkInterface.getLanguage()
      if (lang) {
        webInterface.setLanguage(lang)
      }
    }

    function _setWrapVars() {
      // wait for DOM to refresh for mainWrap to be set
      nextTick(() => {
        setBoundaryVars('main', mainWrap.value.getBoundingClientRect())
      })
    }

    return {
      SCROLL_TO_ID,
      container,
      DARK_MODE,
      mainWrap,
    }
  },
}
</script>
